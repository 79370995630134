// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2021 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import {
  HalResourceEditingService,
} from 'core-app/shared/components/fields/edit/services/hal-resource-editing.service';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { ConfigurationService } from 'core-app/core/config/configuration.service';
import { OPContextMenuService } from 'core-app/shared/components/op-context-menu/op-context-menu.service';
import { ToastService } from 'core-app/shared/components/toaster/toast.service';
import { I18nService } from 'core-app/core/i18n/i18n.service';
import { getPosition } from 'core-app/shared/helpers/set-click-position/set-click-position';
import { EditFormComponent } from 'core-app/shared/components/fields/edit/edit-form/edit-form.component';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
import { UntilDestroyedMixin } from 'core-app/shared/helpers/angular/until-destroyed.mixin';
import { SchemaCacheService } from 'core-app/core/schemas/schema-cache.service';
import {
  displayClassName,
  DisplayFieldRenderer,
  editFieldContainerClass,
} from 'core-app/shared/components/fields/display/display-field-renderer';
import { States } from 'core-app/core/states/states.service';
import { debugLog } from '../../../../helpers/debug_output';
import { hasSelectionWithin } from '../../../../helpers/selection-helpers';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { APIV3Service } from 'core-app/core/apiv3/api-v3.service';
import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'editable-attribute-field',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './editable-attribute-field.component.html',
})
export class EditableAttributeFieldComponent extends UntilDestroyedMixin implements OnInit, OnDestroy {
  @Input() public fieldName:string;

  @Input() public resource:HalResource;

  @Input() public wrapperClasses?:string;

  @Input() public displayFieldOptions:any = {};

  @Input() public displayPlaceholder?:string;

  @Input() public isDropTarget?:boolean = false;

  @ViewChild('displayContainer', { static: true }) readonly displayContainer:ElementRef;

  @ViewChild('editContainer', { static: true }) readonly editContainer:ElementRef;

  public modifyInBaseWpPath:string;

  public listWpSigmaIds:string;

  public fieldRenderer:DisplayFieldRenderer;

  public editFieldContainerClass = editFieldContainerClass;

  public active = false;

  private $element:JQuery;

  public destroyed = false;

  public resourceWpSigma?:HalResource;
  // private customParamsAvailable:any[];

  private workPackagesSigmaIds:any[] = [];
  private workPackagesSigmaIdsHasBeenComputed = false;

  constructor(protected states:States,
    protected injector:Injector,
    protected httpClient:HttpClient,
    protected elementRef:ElementRef,
    protected ConfigurationService:ConfigurationService,
    protected opContextMenu:OPContextMenuService,
    protected halEditing:HalResourceEditingService,
    protected schemaCache:SchemaCacheService,
    protected apiV3Service:APIV3Service,
    readonly halResourceService:HalResourceService,
    // Get parent field group from injector if we're in a form
    @Optional() protected editForm:EditFormComponent,
    protected cdRef:ChangeDetectorRef,
    protected I18n:I18nService) {
    super();
  }

  public isAComputedField() {

    const isAChildrenComputedField = typeof (this.resource[this.fieldName]) === 'number' &&
      this.fieldName.match('customField') &&
      !!this.resource.children &&
      this.resource.children.length > 0;

    if (this.fieldName.indexOf('custom') == -1) {
      return false;
    }

    if (this.workPackagesSigmaIdsHasBeenComputed) {
      const isAParentComputedField = this.workPackagesSigmaIds.indexOf(this.fieldName) !== -1;
      return isAChildrenComputedField || isAParentComputedField;
    }

    if (!this.resourceWpSigma) {
      return isAChildrenComputedField;
    }

    this.workPackagesSigmaIds = this.resourceWpSigma.$source.map((u:number[]) => u[0]) || [];
    this.workPackagesSigmaIdsHasBeenComputed = true;

    const isAParentComputedField = this.workPackagesSigmaIds.indexOf(this.fieldName) !== -1;
    return isAChildrenComputedField || isAParentComputedField;
  }

  public setActive(active = true) {
    this.active = active;
    if (!this.componentDestroyed) {
      this.cdRef.detectChanges();
    }
  }

  public ngOnInit() {
    this.fieldRenderer = new DisplayFieldRenderer(this.injector, 'single-view', this.displayFieldOptions);
    this.$element = jQuery(this.elementRef.nativeElement);

    // this.workPackage.id
    this.modifyInBaseWpPath = this.apiV3Service.work_packages.id({ id: this.resource.$source.id })?.modify.path;
    this.listWpSigmaIds = this.apiV3Service.work_packages.id({ id: this.resource.$source.id })?.listWpSigmaIds.path;

    // Register on the form if we're in an editable context
    this.editForm?.register(this);

    this.halEditing
      .temporaryEditResource(this.resource)
      .values$()
      .pipe(
        this.untilDestroyed(),
      )
      .subscribe((resource) => {
        this.resource = resource;
        this.render();
      });

    this.halResourceService
      .get<any>(this.listWpSigmaIds)
      .subscribe((value) => {
        this.resourceWpSigma = value;
      });
    // If any need, decomment below
    // // fetch the list of all existing custom Params
    // this
    //   .apiV3Service
    //   .projects
    //   .customParams
    //   .get()
    //   .toPromise()
    //   .then((response:HalResource) => {
    //     this.customParamsAvailable = response.$source || [];
    //   });
  }

  private isACustomField():boolean {
    return !!this.fieldName.match('customField');
  }

  private isAChild():boolean {
    return !!this.resource.$source._links.parent &&
      !!this.resource.$source._links.parent.href &&
      !!this.resource.$source._links.parent.title;
  }

  private isANumericValue():boolean {
    const type = this.fieldRenderer.fieldCache[this.fieldName].schema.type;
    return type === 'Float' || type === 'Integer';
  }

  private getFieldId(fieldName:string):number {
    return parseInt(fieldName.replace('customField', ''));
  }

  private async modifyWp(customizedId:string, customFieldId:string, value:string):Promise<HalResource> {

    const isRollbackParent = !this.resource.parent;
    let parent = isRollbackParent ? this.resource : this.resource.parent.$source;
    let newLockVersion = parent.lockVersion + 1;

    if (isNaN(newLockVersion)) {
      const href = this.resource.parent.$source._links.self.href;
      parent = await this.httpClient.get(href, {
        withCredentials: true,
        responseType: 'json',
      }).toPromise() as WorkPackageResource;
      newLockVersion = parent.lockVersion + 1;
    }

    console.log(`newLockVersion for parent (root): ${newLockVersion}`);

    const params = {
      customized_id: customizedId,
      custom_field_id: customFieldId,
      value: value,
      parent_wp_id: parent.id,
      new_lock_version: (newLockVersion === null ? 0 : newLockVersion),
    };
    return new Promise((res) => this.halResourceService
      .post<any>(this.modifyInBaseWpPath, params)
      .subscribe((value) => {
        res(value);

        // Force reload to refresh view on wp/details/overview
        if ((location.href.indexOf('work_packages') !== -1 &&
            location.href.indexOf('details') !== -1 &&
            location.href.indexOf('overview') !== -1) ||
          (location.href.indexOf('work_packages') !== -1 &&
            location.href.indexOf('projects') !== -1 &&
            location.href.indexOf('activity') !== -1)
        ) {
          setTimeout(() => {
            location.reload();
          }, 1500);
        }
      }),
    );
  }

  /**
   * Deprecated method
   * @param resource
   * @param value
   * @private
   */
  private async patchParentWorkPackage(resource:HalResource, value:number):Promise<void> {
    try {

      const parentWpResource = resource.parent.$source;

      // http://localhost:4200/api/v3/work_packages/5/activities
      // this.resource.parent = await this.apiV3Service.work_packages.halResourceService.get('/api/v3/work_packages/' + parentWpResource.id);

      parentWpResource[this.fieldName] = value;

      // update cached values
      await this.apiV3Service.work_packages.cache.updateWorkPackage(parentWpResource, true);

      let payload = {
        lockVersion: parentWpResource.lockVersion,
        _links: {},
      } as any;
      payload[this.fieldName] = parentWpResource[this.fieldName];

      // update parent work package and lockVersion
      await this.apiV3Service.work_packages.halResourceService.patch(
        '/api/v3/work_packages/' + parentWpResource.id,
        payload,
        { withCredentials: true, responseType: 'json' },
      ).toPromise();

      // update estimatedTime work package and lockVersion
      const payload2 = {
        lockVersion: resource.$source.lockVersion,
        _links: {},
        estimatedTime: resource.$source['estimatedTime'],
      } as any;

      await this.apiV3Service.work_packages.halResourceService.patch(
        '/api/v3/work_packages/' + resource.$source.id,
        payload2,
        { withCredentials: true, responseType: 'json' },
      ).toPromise();

      console.log(`parent work package with id ${parentWpResource.id} has been updated`)
      console.log(`estimated Time for work package with id ${resource.$source.id} has been updated`)

    } catch (e) {
      console.error(e);
    }
  }

  // Open the field when its closed and relay drag & drop events to it.
  public startDragOverActivation(event:JQuery.TriggeredEvent) {
    if (!this.isDropTarget || !this.isEditable || this.active) {
      return true;
    }

    this.handleUserActivate(null);
    event.preventDefault();
    return false;
  }

  public render() {
    const el = this.fieldRenderer.render(this.resource, this.fieldName, null, this.displayPlaceholder);
    this.displayContainer.nativeElement.innerHTML = '';
    this.displayContainer.nativeElement.appendChild(el);
  }

  public deactivate(focus = false) {
    this.editContainer.nativeElement.innerHTML = '';
    this.editContainer.nativeElement.hidden = true;
    this.setActive(false);

    if (focus) {
      setTimeout(() => this.$element.find(`.${displayClassName}`).focus(), 20);
    }
  }

  public get isEditable():boolean {
    return this.editForm && this.schema.isAttributeEditable(this.fieldName);
  }

  public showCustomForbiddenCursor():boolean {
    return !!this.workPackagesSigmaIds && this.workPackagesSigmaIds.indexOf(this.fieldName) !== -1;
  }

  public activateIfEditable(event:MouseEvent|KeyboardEvent) {
    this.workPackagesSigmaIds = !!this.resourceWpSigma ? this.resourceWpSigma.$source.map((u:number[]) => 'customField' + u[0]) : [];
    const isAParentComputedField = this.workPackagesSigmaIds.indexOf(this.fieldName) !== -1;
    if (isAParentComputedField) {
      const target = jQuery(event.target as HTMLElement);
      target[0].style.cursor = 'not-allowed';
      return;
    }

    // Ignore selections
    if (hasSelectionWithin(event.target as HTMLElement)) {
      debugLog(`Not activating ${this.fieldName} because of active selection within`);
      return true;
    }

    // Skip activation if the user clicked on a link or within a macro
    const target = jQuery(event.target as HTMLElement);
    if (target.closest('a,macro', this.displayContainer.nativeElement).length > 0) {
      return true;
    }

    if (this.isEditable) {
      this.handleUserActivate(event);
    }

    this.opContextMenu.close();
    event.preventDefault();
    event.stopImmediatePropagation();

    return false;
  }

  public activateOnForm(noWarnings = false) {
    // Activate the field
    this.setActive(true);

    return this.editForm
      .activate(this.fieldName, noWarnings)
      .catch(() => this.deactivate(true));
  }

  public handleUserActivate(evt:MouseEvent|KeyboardEvent|null) {
    let positionOffset = 0;

    if (evt?.type === 'click') {
      // Get the position where the user clicked.
      positionOffset = getPosition(evt);
    }

    this.activateOnForm()
      .then((handler) => {
        if (!handler) {
          return;
        }

        handler.$onUserActivate.next();
        handler.focus(positionOffset);
      });

    return false;
  }

  public reset() {
    if (this.isACustomField() &&
      this.isANumericValue()) {

      console.log('reducing all children values and calling API endpoint to set value for parent WorkPackage Custom field value');
      console.log(`Api call ${this.modifyInBaseWpPath}`);

      const useRollbackParent = !this.resource.parent;
      const rollbackParent = useRollbackParent ? this.resource : this.resource.parent;
      const customizedId = rollbackParent.id;
      const customFieldId = this.getFieldId(this.fieldRenderer.fieldCache[this.fieldName].name);

      (async () => {

        const parent = useRollbackParent ? this.resource : await this.resource.$links.parent;
        let parentChildren = parent?.children
        if (!parent.children) {
          const response = await this.httpClient.get(parent.$link.href, {
            withCredentials: true,
            responseType: 'json',
          }).toPromise() as WorkPackageResource;
          parentChildren = response._links.children;
        }
        const arrayOfChildrenLinks = !!parentChildren ? parentChildren.map((u:{ href:string, $load?:() => Promise<any>; }) => {
          if (!!u.$load) { return u.$load()} else {
            return this.httpClient.get(u.href, {
              withCredentials: true,
              responseType: 'json',
            }).toPromise()
          }
        }) : [];
        const res = await Promise.all(arrayOfChildrenLinks);
        const valToWriteInDb = !useRollbackParent ? res.reduce((a:WorkPackageResource, b:WorkPackageResource) => {
            const valB = b[this.fieldName] === null ? 0 : b[this.fieldName];
            return a + valB;
          },
          0) : this.resource[this.fieldName];

        console.log(`Api Params customizedId: ${customizedId} , customFieldId: ${customFieldId}, value: ${valToWriteInDb}`);
        console.log(`Parent id is : ${this.resource?.parent?.$source?.id}`);

        this.modifyWp(
          customizedId + '',
          customFieldId + '',
          valToWriteInDb + '',
        ).then(async () => {
          console.log('modifyWp called successfully');
        }).catch(e => console.error(e));

      })();
    }
    this.render();
    this.deactivate();


  }

  private get schema() {
    if (this.halEditing.typedState(this.resource).hasValue()) {
      return this.halEditing.typedState(this.resource).value!.schema;
    }
    return this.schemaCache.of(this.resource);
  }
}
