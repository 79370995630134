import { Ng2StateDeclaration } from '@uirouter/angular';
import {
  NewImportProjectComponent
} from 'core-app/features/import-projects/components/new-import-project/new-import-project.component';

export const IMPORT_PROJECTS_ROUTES:Ng2StateDeclaration[] = [
  {
    name: 'new_import_project',
    url: '/import/projects',
    component: NewImportProjectComponent,
  },
];


