<ng-container *ngIf="canAdd || canReference">
  <tr *ngIf="mode === 'inactive'"
      class="wp-inline-create-button-row hide-when-print">
    <td [attr.colspan]="colspan"
        class="wp-inline-create-button-td -no-highlighting -no-ellipsis">
      <div class="wp-inline-create-button">
        <button
          *ngIf="canAdd"
          type="button"
          class="wp-inline-create--add-link"
          [class.wp-inline-create--split-link]="hasReferenceClass"
          [opAutofocus]="focus"
          (click)="handleAddRowClick()"
          [attr.aria-label]="text.create"
          aria-haspopup="true"
        >
          <op-icon icon-classes="icon icon-add"></op-icon>
          <span [textContent]="text.create"></span>
        </button>
        <ng-container *ngIf="canReference">
          <button
            type="button"
            class="wp-inline-create--reference-link wp-inline-create--split-link"
            (click)="handleReferenceClick()"
            [attr.aria-label]="text.create"
            aria-haspopup="true"
          >
<!--            <op-icon icon-classes="icon icon-link"></op-icon>-->
<!--            <span [textContent]="text.reference"></span>-->
          </button>
        </ng-container>
      </div>
    </td>
  </tr>
  <tr *ngIf="hasReferenceClass && mode === 'reference'"
      class="wp-inline-create-button-row hide-when-print">
    <td [attr.colspan]="colspan"
        class="wp-inline-reference-row-td -no-highlighting">
      <ng-container *ngComponentOutlet="referenceClass; injector: injector"></ng-container>
    </td>
  </tr>
</ng-container>
