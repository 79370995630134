<br>
<br>
<span style="font-style: italic">Cette interface permet d'importer une liste de projets
  à partir d'un fichier Excel dont le modèle doit être fourni par votre administrateur.
  <br>
  <br>
 Il est recommandé de ne pas importer plusieurs fois le même fichier (bouton "Importer les projets").
  <br>
  <br>
  Veuillez d'abord vous assurer que les projets n'ont pas été importés
</span>
<br>
<br>
<br>
<br>
<input type="file" style="display: inline-block;"
       (click)="raz($event)"
       (change)="incomingfile($event)"
       id="inputFile"
       style="display: none;"
       placeholder="Importer mes projets excels" accept=".xlsx">

<button type="button"
        style="cursor: pointer"
        class="btn button"
        (click)="clickOnInputFile()">
  Sélectionner un fichier .xlsx
</button>
<button type="button" class="btn button -highlight"
        style="cursor: pointer"
        [disabled]="!jsonExcel||oneCheckFailed||importSuccessfull"
        (click)="uploadToNewProjectApi()">
  Importer les projets
</button>

<br>
<br>
<br>

<ng-container *ngIf="oneCheckFailed && jsonExcel">
  <span>Veuillez corriger les erreurs dans le fichier avant de le sélectionner de nouveau</span>
</ng-container>

<ng-container *ngIf="!oneCheckFailed && jsonExcel">
  <span *ngIf="!importSuccessfull">Données à importer </span>
  <span *ngIf="importSuccessfull" style="color: limegreen">Données importées! </span>
</ng-container>

<br>
<br>

<ng-container *ngIf="jsonExcel">
  <table id="customers">
    <th *ngFor="let key of keys">
      {{key}}
    </th>
    <tr *ngFor="let item of jsonExcel;let indexOfelement=index;">
      <td *ngFor="let key of keys" [style]="(errorWithRow-1)===indexOfelement?'background-color:red':''">
        {{item[key]}}
      </td>
    </tr>
  </table>
</ng-container>