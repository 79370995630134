// -- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2021 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import { StateService } from '@uirouter/core';
import { Component, Injector, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  WorkPackageViewSelectionService,
} from 'core-app/features/work-packages/routing/wp-view-base/view-services/wp-view-selection.service';
import {
  WorkPackageSingleViewBase,
} from 'core-app/features/work-packages/routing/wp-view-base/work-package-single-view.base';
import { HalResourceNotificationService } from 'core-app/features/hal/services/hal-resource-notification.service';
import {
  WorkPackageNotificationService,
} from 'core-app/features/work-packages/services/notifications/work-package-notification.service';
import { WpSingleViewService } from 'core-app/features/work-packages/routing/wp-view-base/state/wp-single-view.service';
import { WpDestroyModalComponent } from 'core-app/shared/components/modals/wp-destroy-modal/wp-destroy.modal';
import { OpModalService } from 'core-app/shared/components/modal/modal.service';
import { HttpClient } from '@angular/common/http';
import { HalResourceService } from 'core-app/features/hal/services/hal-resource.service';
import { HalResource } from 'core-app/features/hal/resources/hal-resource';
import {
  TimeEntriesCurrentUserConfigurationModalComponent,
} from 'core-app/shared/components/grids/widgets/time-entries/current-user/configuration-modal/configuration.modal';

@Component({
  templateUrl: './wp-full-view.html',
  selector: 'wp-full-view-entry',
  // Required class to support inner scrolling on page
  host: { class: 'work-packages-page--ui-view' },
  providers: [
    WpSingleViewService,
    { provide: HalResourceNotificationService, useExisting: WorkPackageNotificationService },
  ],
})
export class WorkPackagesFullViewComponent extends WorkPackageSingleViewBase implements OnInit {
  // Watcher properties
  public isWatched:boolean;

  public displayWatchButton:boolean;

  public watchers:any;

  public modifyInBaseWpPath:string;

  stateName$ = of('work-packages.new');

  constructor(
    public injector:Injector,
    public wpTableSelection:WorkPackageViewSelectionService,
    readonly $state:StateService,
    readonly opModalService:OpModalService,
    private httpClient:HttpClient,
    private halResourceService:HalResourceService,
  ) {
    super(injector, $state.params.workPackageId);
  }

  ngOnInit():void {
    this.observeWorkPackage();
    this.modifyInBaseWpPath = this.apiV3Service.work_packages.id({ id: 'IdToReplace' })?.modify.path;
  }

  protected initializeTexts() {
    super.initializeTexts();

    this.text.full_view = {
      button_more: this.I18n.t('js.button_more'),
    };
  }

  protected init() {
    super.init();

    // Set Focused WP
    this.wpTableFocus.updateFocus(this.workPackage.id!);

    this.setWorkPackageScopeProperties(this.workPackage);
  }

  private setWorkPackageScopeProperties(wp:WorkPackageResource) {
    this.isWatched = wp.hasOwnProperty('unwatch');
    this.displayWatchButton = wp.hasOwnProperty('unwatch') || wp.hasOwnProperty('watch');

    // watchers
    if (wp.watchers) {
      this.watchers = (wp.watchers as any).elements;
    }
  }

  deleteCurrentWp() {

    // text: I18n.t('js.work_packages.bulk_actions.delete'),
    //   key: 'delete',
    //   link: 'delete',
    //   href: `${this.PathHelper.staticBase}/work_packages/bulk?_method=delete`,
    this.opModalService
      .show(WpDestroyModalComponent, this.injector, { workPackages: [this.workPackage] })
      .closingEvent.subscribe((modal:any) => {

      if (modal.closingEvent.isStopped) {
        return;
      }

      // TODO: refactor this in a common service too!
      (async () => {

        if (!this.workPackage?.$source._links?.parent?.href) {
          return;
        }

        const parent = await this.httpClient.get(this.workPackage?.$source._links?.parent?.href!, {
          withCredentials: true,
          responseType: 'json',
        }).toPromise() as unknown as WorkPackageResource;
        const customizedId = parent?.id;

        let parentChildren = parent?._links.children;

        // @ts-ignore
        const arrayOfChildrenLinks = parentChildren.map((u:{ href:string, $load?:() => Promise<any>; }) => {
          if (!!u.$load) { return u.$load()} else {
            return this.httpClient.get(u.href, {
              withCredentials: true,
              responseType: 'json',
            }).toPromise()
          }
        });
        const res = await Promise.all(arrayOfChildrenLinks);

        const customFieldIds = Object.keys(this.workPackage)
          .filter(u => u.indexOf('customField') !== -1 && typeof (this.workPackage[u]) === 'number')
          .map(u => u.replace('customField', ''));

        for (let customFieldId of customFieldIds) {

          const valToWriteInDb = res.reduce((a:any, b:any) => {
              const valB = b['customField' + customFieldId] === null ? 0 : b['customField' + customFieldId];
              const valToAdd = (!valB || typeof (valB) !== 'number') ? 0 : valB;
              return a + valToAdd;
            },
            0);

          console.log(`Api Params customizedId: ${customizedId} , customFieldId: ${customFieldId}, value: ${valToWriteInDb}`);
          console.log(`Parent id is : ${parent?.id}`);

          await this.modifyWp(
            parent,
            customizedId + '',
            customFieldId + '',
            valToWriteInDb + '',
          );

        }

        setTimeout(()=>{
          location.reload();
        },1000);


      })();
    });

  }


  /**
   * TODO: REFACTOR in a common Service
   * @param parent
   * @param customizedId
   * @param customFieldId
   * @param value
   * @private
   */
  private modifyWp(parent:any, customizedId:string, customFieldId:string, value:string):Promise<HalResource> {
    const newLockVersion = parent?.lockVersion + 1;
    console.log(`newLockVersion for parent (root): ${newLockVersion}`);

    const params = {
      customized_id: customizedId + '',
      custom_field_id: customFieldId + '',
      value: value + '',
      parent_wp_id: parent.id + '',
      new_lock_version: (newLockVersion === null ? 0 : newLockVersion),
    };
    return new Promise((res) => this.halResourceService
      .post<any>(this.modifyInBaseWpPath.replace('IdToReplace', parent.id), params)
      .subscribe((value:HalResource|PromiseLike<HalResource>) => {
        res(value);
      }),
    );

  }

}
