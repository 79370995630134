export const PERMITTED_CONTEXT_MENU_ACTIONS = [
  {
    key: 'log_time',
    link: 'logTime',
    resource: 'workPackage',
  },
  {
    key: 'change_project',
    icon: 'icon-move',
    link: 'move',
    resource: 'workPackage',
  },
  {
    key: 'copy',
    link: 'copy',
    resource: 'workPackage',
  },
  // {
  //   key: 'delete',
  //   link: 'delete',
  //   resource: 'workPackage',
  // },
  {
    key: 'export-pdf',
    link: 'pdf',
    resource: 'workPackage',
  },
  {
    key: 'export-atom',
    link: 'atom',
    resource: 'workPackage',
  },
];
