<div [hidden]="isAComputedField()">
  <div [ngClass]="[
      editFieldContainerClass,
      fieldName,
      active && '-active' || '',
      wrapperClasses || '-small',
     ]"
       (dragover)="startDragOverActivation($event)">

    <div #editContainer
         [hidden]="!active">
    </div>

    <div (click)="activateIfEditable($event)"
         (keydown.enter)="activateIfEditable($event)"
         [hidden]="active"
         tabindex="-1"
         #displayContainer></div>
  </div>
</div>
<div *ngIf="isAComputedField()">
  <span>{{resource[fieldName]}}</span>
</div>
