import { WorkPackageResource } from 'core-app/features/hal/resources/work-package-resource';
import {
  DisplayFieldRenderer,
  editFieldContainerClass, noEditFieldContainerClass,
} from 'core-app/shared/components/fields/display/display-field-renderer';
import { Injector } from '@angular/core';
import { QueryColumn } from 'core-app/features/work-packages/components/wp-query/query-column';
import { SchemaCacheService } from 'core-app/core/schemas/schema-cache.service';
import { InjectField } from 'core-app/shared/helpers/angular/inject-field.decorator';

export const tdClassName = 'wp-table--cell-td';
export const editCellContainer = 'wp-table--cell-container';

export class CellBuilder {
  @InjectField(SchemaCacheService) schemaCache:SchemaCacheService;

  public fieldRenderer = new DisplayFieldRenderer(this.injector, 'table');

  constructor(public injector:Injector) {
  }

  public build(workPackage:WorkPackageResource, column:QueryColumn) {
    const td = document.createElement('td');
    const attribute = column.id;
    td.classList.add(tdClassName, attribute);

    if (attribute === 'subject') {
      td.classList.add('-max');
    }

    const schema = this.schemaCache.of(workPackage).ofProperty(attribute);
    if (schema && schema.type === 'User') {
      td.classList.add('-contains-avatar');
    }


    let htmlElt = null;

    const isALockedParent = !!column?.$source && column?.$source.name.toUpperCase().indexOf('PARENT') !== -1;
    const containerLockedParent = document.createElement('span');
    isALockedParent ?
      containerLockedParent.classList.add(editCellContainer, noEditFieldContainerClass, attribute) :
      containerLockedParent.classList.add(editCellContainer, editFieldContainerClass, attribute);

    // we override HTML elt for custom params
    if (isALockedParent) {
      htmlElt = (document.createElement('a'));
      const href = workPackage?.$source?._links?.parent?.href;
      const attributeValue = !!href ? href.split('work_packages/')[1] : '';
      htmlElt.innerText = !!attributeValue ? '#' + attributeValue : '';
      htmlElt.href = window.location.href.split('work_packages')[0] + 'work_packages/' + attributeValue;
      containerLockedParent.appendChild(htmlElt);
      td.appendChild(containerLockedParent);
      return td;
    }

    const isALockedCustomField = !!column?.$source && column?.$source.id.indexOf('customField') !== -1;
    const container = document.createElement('span');
    isALockedCustomField ?
      container.classList.add(editCellContainer, noEditFieldContainerClass, attribute) :
      container.classList.add(editCellContainer, editFieldContainerClass, attribute);


    // we override HTML elt for custom params
    if (isALockedCustomField) {
      htmlElt = (document.createElement('span'));
      const attributeValue = workPackage.$source[attribute];
      htmlElt.innerText = !attributeValue && typeof (attributeValue) !== 'number' ? '' : '' + workPackage.$source[attribute]
      container.appendChild(htmlElt);
      td.appendChild(container);
      return td;
    }

    const displayElement = this.fieldRenderer.render(workPackage, attribute, null);
    container.appendChild(displayElement);
    td.appendChild(container);

    return td;
  }

  public refresh(container:HTMLElement, workPackage:WorkPackageResource, attribute:string) {
    const displayElement = this.fieldRenderer.render(workPackage, attribute, null);

    container.innerHTML = '';
    container.appendChild(displayElement);
  }
}
